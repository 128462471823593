import React from 'react';
import{useRouter} from '../utils/router'

function Projects() {
  const router = useRouter();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
      <h3 className='text-fonts'>Projects</h3>

      <button
        style={{
          backgroundColor: 'blue',
          color: 'white',
          width: 240,
          height: 56,
          fontSize: '1rem'
        }}
        onClick={() => router.push('/sudoku')}
      >
        Sudoku
      </button>
    </div>
  );
}
export default Projects;
