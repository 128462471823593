import React from 'react';
import useSudoku from './useSudoku';

function MyView() {
  let sudoku = useSudoku();
  return (
    <>
      {sudoku}
      {sudoku}
    </>
  );
}
export default MyView;
