import React from 'react';
import '../styles.css';

export default function About() {
  return (
    <div className='page-container'>
      <div className='image-card'>
        icqual
      </div>
    </div>
  );
}
