import React, { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../../utils/useOutsideClick';
import '../styles/GameStyles.css';
import '../../../styles.css';

function Sudoku() {
  const ref = useRef();
  const [baseMatrix, setBaseMatrix] = useState();
  async function setBasics() {
    let ar = [];
    let b = [];
    let i = 1;
    for (i = 1; i < 10; i++) {
      b.push(i);
    }
    shuffle(b);
    ar[0] = b.slice();
    i = 1;
    for (; i < 9; i++) {
      let t = b.shift();
      b.push(t);
      ar[i] = b.slice();
    }
    setBaseMatrix(ar);
    if (clicked) clicked.style.backgroundColor = origColor;
  }

  const [gameMatrix, setGameMatrix] = useState();
  useEffect(() => {
    function shuffleGameMatrix() {
      let rows = 9,
        cols = 9;
      let rowStarts = [3, 0, 6];
      let blockLenght = 3;

      shuffle(rowStarts);

      let tmp = [];
      //create row blocks of 3 rows
      for (let i = 0; i < blockLenght; i++) {
        rowStarts.forEach(r => {
          tmp.push(baseMatrix[r + i]);
        });
      }
      // console.log('Rows blocked 3x3');
      // tmp.forEach(r => console.log(r.toString()));
      //create column blocks of 3 columns
      shuffle(rowStarts);
      let tmpa = [];
      for (let j = 0; j < rows; j++) {
        let row = [];
        rowStarts.forEach(r => {
          for (let i = 0; i < blockLenght; i++) {
            row.push(tmp[j][r + i]);
          }
        });
        tmpa.push(row);
      }
      console.log('Cols blocked 3x3');
      tmpa.forEach(r => console.log(r.toString()));
      setGameMatrix(tmpa);
    }
    if (baseMatrix && baseMatrix.length > 0) {
      shuffleGameMatrix();
    }
  }, [baseMatrix]);

  const [level, setLevel] = useState(2);
  function setChallengeLevel(e) {
    console.log(e.currentTarget.value);
    setLevel(e.currentTarget.value);
  }

  const [visible, setVisible] = useState();
  useEffect(() => {
    function visibleMatrix() {
      const numbers = [...Array(81).values()].map(num =>
        Math.floor(Math.random() * level)
      );
      shuffle(numbers);
      let v = numbers.map((num, index) => {
        if (num === 1) {
          const [quotient, remainder, error = 0] = getLevelForArrayIndex(index);
          if (error) return 0;
          return gameMatrix[quotient][remainder];
        } else {
          return 0;
        }
      });
      //get all numbers
      let vis = [];
      for (let i = 0; i < 9; i++) {
        vis[i] = v.slice(i * 9, (i + 1) * 9);
      }
      //get the count of visible fields
      let l = 0;
      vis.forEach(r => (l += r.filter(n => n !== 0).length));
      console.log(l);
      vis.forEach(r => console.log(r.toString().replace(/0/g, '-')));
      //show visible
      setVisible(vis);
    }
    if (gameMatrix && gameMatrix.length) {
      visibleMatrix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameMatrix]);

  function getLevelForArrayIndex(index) {
    if (index) {
      let quotient = Math.floor(index / 9);
      let remainder = index < 9 ? parseInt(index) : index % 9;
      return [quotient, remainder];
    } else {
      return [null, null, 1];
    }
  }

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let k = Math.floor(Math.random() * i);
      [array[i], array[k]] = [array[k], array[i]];
    }
  }
  const [html, setHtml] = useState(null);
  const [clicked, setClicked] = useState();
  const [origColor, setOrigColor] = useState();
  const [showPopup, setShowPopup] = useState();
  const [popupPoint, setPopupPoint] = useState([128, 128]);
  useEffect(() => {
    function renderNumbers() {
      let html = [];
      let index = 0;
      for (let i = 0; i < 9; i++)
        for (let j = 0; j < 9; j++, index++) {
          html.push(
            <div
              key={index}
              id={index}
              data-scope={visible[i][j] ? 'system' : 'user'}
              style={{
                backgroundColor:
                  (i < 3 && j < 3) ||
                  (i < 3 && j > 5) ||
                  (i > 5 && j < 3) ||
                  (i > 5 && j > 5)
                    ? 'white'
                    : 'lightGrey',
                width: 32,
                height: 32,
                textAlign: 'center',
                lineHeight: 2
              }}
              onClick={e => onClick(e)}
            >
              {visible[i][j] ? visible[i][j] : ''}
            </div>
          );
        }
      setHtml(html);
    }
    function onClick(elm) {
      const [quotient, remainder, error = 0] = getLevelForArrayIndex(
        elm ? elm.currentTarget.id : null
      );

      if (error || visible[quotient][remainder] !== 0) {
        if (clicked) clicked.style.backgroundColor = origColor;
        return;
      }
      console.log(elm.currentTarget.id);
      let rect = elm.currentTarget.getBoundingClientRect();
      if (remainder === 8) {
        setPopupPoint([rect.top - quotient * 40, rect.left - 48]);
      } else if (quotient === 8) {
        setPopupPoint([rect.top - quotient * 40, rect.right]);
      } else {
        setPopupPoint([rect.top - quotient * 40, rect.right]);
      }

      console.log(rect.top, rect.right, rect.bottom, rect.left);
      setShowPopup(true);

      //before setting newly clicked, restore the previous state of color
      if (clicked) clicked.style.backgroundColor = origColor;
      //now set new clicked div
      setClicked(elm.currentTarget);
      setOrigColor(elm.currentTarget.style.backgroundColor);
      if (visible[quotient][remainder] === 0) {
        elm.currentTarget.style.backgroundColor = 'orange';
      }
    }

    if (visible && visible.length > 0) {
      renderNumbers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked, visible]);

  const [userChoices, setUserChoices] = useState();
  useEffect(() => {
    let tmp = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    let utmp = new Array(9);
    for (let i = 0; i < 9; i++) utmp[i] = tmp.slice();
    console.log(utmp);
    setUserChoices(utmp);
  }, []);

  function setUserChoice(choice) {
    let [q, r, e = 0] = getLevelForArrayIndex(clicked.id);
    if (e) return;
    console.log(`Element row = ${q}, column = ${r}`);

    userChoices[q][r] = parseInt(choice, 10);
    console.log(userChoices);
  }

  function handleClick(e) {
    //alert(`Selected ${e.currentTarget.innerText}`);
    if (e.currentTarget.innerText === 'X') {
      clicked.innerText = '';
      clicked.style.color = 'black';
      setShowPopup(false);
      setUserChoice('0');
    } else {
      clicked.innerText = e.currentTarget.innerText;
      clicked.style.color = 'grey';
      setUserChoice(e.currentTarget.innerText);
      setShowPopup(false);
    }
  }

  function closeRef() {
    setShowPopup(false);
  }

  useOutsideClick(ref, closeRef);

  return (
    <div>
      <div className='controls'>
        <button
          style={{
            backgroundColor: 'blue',
            color: 'white',
            width: 120,
            height: 48,
            fontSize: '1rem',
            fontFamily: 'Cera Basic Regular'
          }}
          onClick={setBasics}
        >
          New Game
        </button>
        <input
          type='range'
          id='challenge'
          name='challenge'
          min='2'
          max='12'
          step='0.1'
          value={level}
          className='level-style'
          onChange={e => setChallengeLevel(e)}
        ></input>
        <label className='text-fonts level-style' htmlFor='challenge'>
          Level {(level - 2).toFixed(2)}
        </label>
      </div>
      <div className='grid-container'>
        <div className='grid-holder'>{html}</div>
        {showPopup && (
          <div
            ref={ref}
            style={{
              position: 'absolute',
              top: popupPoint[0],
              left: popupPoint[1],
              backgroundColor: 'yellow',
              fontSize: '1.2rem'
            }}
          >
            <ul
              style={{
                listStyleType: 'none',
                padding: '0.5rem'
              }}
            >
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                X
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                1
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                2
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                3
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                4
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                5
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                6
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                7
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                8
              </li>
              <li style={{ margin: '0.5rem' }} onClick={e => handleClick(e)}>
                9
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sudoku;
