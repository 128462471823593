import React, { useState } from 'react';
import ContentEditable from 'react-sane-contenteditable';

export default function MyEditor() {
  const [title, setTitle] = useState('');

  function handleChange(ev, value) {
    setTitle(value);
  }
  return (
    <div className='my-editor'>
      <ContentEditable
        tagName='h1'
        className='my-class'
        content={title}
        editable={true}
        maxLength={140}
        multiLine={true}
        onChange={handleChange}
      />
    </div>
  );
}
