import React from 'react';
import '../styles.css';

export default function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-style text-fonts'>
        <div>
          <a
            href='mailto:ivan@icqual.com'
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Send email
          </a>
        </div>
        <div>
          <a
            href='https://linkedin.com/in/chchkv'
            style={{ textDecoration: 'none', color: 'inherit' }}
            rel='noopener noreferrer' target='_blank'
          >
            Check linkedin
          </a>
        </div>
        <div>
          <a
            href='tel:+359888450523'
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Call mobile
          </a>
        </div>
      </div>
    </div>
  );
}
