import React from 'react';
import { Route, Router, Switch } from './utils/router';
import Home from './home/Home';
import About from './about/About';
import Projects from './projects/Projects';
import Contact from './contact/Contact';
import Appbar from './appbar/Appbar';
import './App.css';
import Footer from './footer/Footer';
import MyEditor from './editor/MyEditor';
import Sudoku from './projects/sudoku/engine/Sudoku';
import MyView from './projects/sudoku/View';

function App() {
  return (
    <Router>
      <div>
        <Appbar />
        <div>
          <Switch>
            <Route path='/editor' component={MyEditor} />
            <Route path='/about' component={About} />
            <Route path='/contact' component={Contact} />
            <Route path='/projects' component={Projects} />
            <Route path='/sudoku' component={Sudoku} />
            <Route path='/myview' component={MyView} />
            <Route path='/**' component={Home} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
