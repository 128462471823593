import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import '../styles.css';

function Home() {
  return (
    <div className='page-container vertical-flow'>
      <h3 className='text-fonts'>Short story</h3>
      <body className='text-fonts'>
        It started years ago as a consulting activity of mine and built-up through some
        interesting projects, around:
      </body>
      <li className='text-fonts'>Windows mobile and desktop applications</li>
      <li className='text-fonts'>HTML5 consulting</li>
      <li className='text-fonts'>Andorid and iOS applications</li>
      <li className='text-fonts'>Web applications</li>
      <li className='text-fonts'>Cloud integrations</li>
      <li className='text-fonts'>Microservices and serverless solutions</li>
      <body className='text-fonts'>
        The dynamics in the software industry require continous adaptation.
        Technologies come and go. Our enthusiasm with them. We work to stay
        competitive, learn and challenge ourselves.
      </body>
      <p className='text-fonts-small'>And this is fun!</p>
    </div>
  );
}
export default Home;
